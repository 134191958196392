import moment from 'moment';
import { Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../utils/select';
import { isNumber, validateNumber } from '../../utils/validations';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvButton } from '../../common/components/LrvButton/LrvButton';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { LrvDivider } from '../../common/components/LrvDivider/LrvDivider';
import { LrvCheckbox } from '../../common/components/LrvCheckbox/LrvCheckbox';
import { getStartDate } from '../../common/components/charts/ShadedPlot/helpers';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../utils/strings';
import { convertKilogramsToPounds, convertPoundsToKilograms, disabledEndDate, getUnitPhaseTypeFromStocking } from '../../helpers/stocking.helpers';
import { containerTypes, DATE_FORMATS, LBS_TO_GR_RATIO, stockingPhaseTypes, stockingStatuses, transferTypes, unitStatuses, weightUnits, weightUnitsByCompany } from '../../config/commons';

import styles from './FinishStocking.module.scss';
import * as finishStockingSlice from './finishStockingSlice';
import { defaultDestinationStocking } from './sowings.helpers';
import DestinationStockingForm from './DestinationStockingForm';
import * as transferStockingSlice from './transferStockingSlice';
import { DataDestinationStocking, TransferStockingDataProps, TransferStockingProps } from './interfaces';

interface Props {
  theme?: 'dark' | 'light';
  pathname: string;
}

const { Option } = Select;

export const TransferForm = (props: Props) => {
  const { theme = 'dark', pathname } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [formTransferStocking] = Form.useForm();

  const {
    plgHarvest: plgHarvestDefault,
    averageWeight,
    stockingFinishedData,
    showModalFinish: showModal,
    isLoadingFinish,
    dataDestinationStockings,
  } = useSelector((state: Store) => state.finishStocking);

  const { company, phaseType } = useSelector((state: Store) => state.header);
  const { stockingsToShow } = useSelector((state: Store) => state.stockings.filters);
  const { isLoadingTransfer } = useSelector((state: Store) => state.transferStocking);
  const { selectedStocking, selectedCampus, selectedModuleId, selectedTankId, currentPage } = useSelector((state: Store) => state.stockings);

  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [plgHarvest, setPlgHarvest] = useState<number | string>('');
  const [poundsTransferred, setPoundsTransferred] = useState<number | string>('');
  const [animalsTransferred, setAnimalsTransferred] = useState<number | string>('');
  const [kilogramsTransferred, setKilogramsTransferred] = useState<number | string>('');
  const [averageHarvestedWeight, setAverageHarvestedWeight] = useState<number | string>('');

  const [fullTransfer, setFullTransfer] = useState<boolean>(true);
  const [newStockingPhaseType, setNewStockingPhaseType] = useState<string>('');

  const isCampusInactive = selectedStocking.campusId.status === unitStatuses.INACTIVE;

  /* useEffect section */
  useEffect(() => {
    const fillFormFinishStocking = () => {
      const endDateStocking = moment();
      setKilogramsTransferred('');
      setPoundsTransferred('');
      setAnimalsTransferred('');
      setEndDate(endDateStocking);

      if (selectedStocking.status === stockingStatuses.ACTIVE || selectedStocking.status === transferTypes.PARTIAL_TRANSFER) {
        setPlgHarvest(plgHarvestDefault);
        setAverageHarvestedWeight(`${averageWeight}`);
      }

      const plgHarvest = selectedStocking.status === stockingStatuses.ACTIVE || selectedStocking.status === transferTypes.PARTIAL_TRANSFER ? `${plgHarvestDefault}` : '';
      const averageHarvestedWeight = selectedStocking.status === stockingStatuses.ACTIVE || selectedStocking.status === transferTypes.PARTIAL_TRANSFER ? `${averageWeight}` : '';

      formTransferStocking.setFieldsValue({
        naupliiHarvested: '',
        poundsHarvested: '',
        kilogramsHarvested: '',
        plgHarvest,
        averageHarvestedWeight,
        endDate: endDateStocking,
        kilogramsTransferred: '',
        poundsTransferred: '',
        animalsTransferred: '',
        phaseType: '',
      });
    };

    if (selectedStocking._id !== '' && showModal === true) {
      fillFormFinishStocking();
    }
  }, [dispatch, formTransferStocking, averageWeight, plgHarvestDefault, selectedStocking, showModal]);

  useEffect(() => {
    if (!showModal) {
      formTransferStocking.resetFields();
    }
  }, [showModal]);

  useEffect(() => {
    if (phaseType === stockingPhaseTypes.ADULT) {
      onChangeStockingPhaseType(stockingPhaseTypes.ADULT);
      return;
    }

    setNewStockingPhaseType('');
    dispatch(finishStockingSlice.setDataDestinationStocking([defaultDestinationStocking]));
  }, [phaseType]);
  /* End of useEffect section */

  /* Functions section */
  const isValidDestinationStocking = (dataDestinationStocking: DataDestinationStocking) => {
    return dataDestinationStocking.campusId && dataDestinationStocking.moduleId && dataDestinationStocking.tankId && dataDestinationStocking.code && dataDestinationStocking.name && dataDestinationStocking.volume;
  };

  const isValidDataDestinationStocking = () => {
    let isValid = true;

    if (dataDestinationStockings.length === 1) {
      const dataDestinationStocking = dataDestinationStockings[0];
      if (dataDestinationStocking.stockingId) {
        return true;
      }

      return isValidDestinationStocking(dataDestinationStocking);
    }

    for (let index = 0; index < dataDestinationStockings.length; index++) {
      const dataDestinationStocking = dataDestinationStockings[index];
      if (dataDestinationStocking.stockingId) {
        continue;
      }

      if (!isValidDestinationStocking(dataDestinationStocking) || !dataDestinationStocking.animalsNumber) {
        isValid = false;
      }
    }

    return isValid;
  };

  const addDestinationStocking = () => {
    const data: DataDestinationStocking[] = [...dataDestinationStockings];
    data.push(defaultDestinationStocking);
    dispatch(finishStockingSlice.setDataDestinationStocking(data));
  };

  const onChangeStockingPhaseType = (value: string) => {
    setNewStockingPhaseType(value);
    dispatch(finishStockingSlice.setDataDestinationStocking([defaultDestinationStocking]));
    const unitPhaseType = getUnitPhaseTypeFromStocking(value);
    dispatch(transferStockingSlice.fetchCampuses({ companyId: company._id, phaseType: unitPhaseType }));
  };

  const calculateKilogramsTransferred = (pounds: string | number) => {
    const kilograms = convertPoundsToKilograms(pounds);

    setKilogramsTransferred(kilograms);
    formTransferStocking.setFieldsValue({
      kilogramsTransferred: kilograms
    });
  };

  const calculatePoundsTransferred = (kilograms: string | number) => {
    const pounds = convertKilogramsToPounds(kilograms);

    setPoundsTransferred(pounds);
    formTransferStocking.setFieldsValue({
      poundsTransferred: pounds
    });
  };

  const calculateShrimpsTransferred = (poundsHarvested: string | number, averageHarvestedWeight: string | number) => {
    let harvestedShrimp = 0;

    if (!!poundsHarvested && isNumber(poundsHarvested) && !!averageHarvestedWeight && isNumber(averageHarvestedWeight) && Number(averageHarvestedWeight) > 0) {
      harvestedShrimp = parseFloat(poundsHarvested.toString()) * LBS_TO_GR_RATIO / parseFloat(averageHarvestedWeight.toString());
      harvestedShrimp = Math.round(harvestedShrimp);
    }

    setAnimalsTransferred(harvestedShrimp);
    formTransferStocking.setFieldsValue({
      animalsTransferred: harvestedShrimp,
    });
  };

  const calculatePlgHarvest = (value: string | number) => {
    const plg = Math.round(1000 / parseFloat(value.toString()) * 100) / 100;

    setPlgHarvest(plg);
    formTransferStocking.setFieldsValue({
      plgHarvest: plg
    });
  };

  const calculateAverageHarvestedWeight = (value: string | number) => {
    const average = Math.round(1000 / parseFloat(value.toString()) * 100) / 100;

    if (Number(value) == 0) {
      setAverageHarvestedWeight('');
      formTransferStocking.setFieldsValue({
        averageHarvestedWeight: '',
      });
      return;
    }

    setAverageHarvestedWeight(average);
    formTransferStocking.setFieldsValue({
      averageHarvestedWeight: average
    });

    calculateShrimpsTransferred(poundsTransferred, average);
  };

  const isValidTransferForm = () => {
    const endDate = formTransferStocking.getFieldValue('endDate');
    const poundsTransferred = formTransferStocking.getFieldValue('poundsTransferred');
    const kilogramsTransferred = formTransferStocking.getFieldValue('kilogramsTransferred');
    const averageHarvestedWeight = formTransferStocking.getFieldValue('averageHarvestedWeight');
    const animalsTransferred = formTransferStocking.getFieldValue('animalsTransferred');

    const { min } = stockingFinishedData.juvenile.averageHarvestedWeight;

    switch (selectedStocking.phaseType) {
      case stockingPhaseTypes.LARVAE: {
        return !!(averageHarvestedWeight && parseFloat(averageHarvestedWeight) >= min && animalsTransferred && endDate && isValidDataDestinationStocking());
      }

      case stockingPhaseTypes.ADULT:
      case stockingPhaseTypes.JUVENILE:
        return !!((poundsTransferred || kilogramsTransferred) && averageHarvestedWeight && parseFloat(averageHarvestedWeight) >= min && animalsTransferred && endDate && isValidDataDestinationStocking());
    }
  };

  const disabledFinishForm = () => {
    if (isCampusInactive) {
      return true;
    }

    return !isValidTransferForm();
  };

  const getAnimalsNumber = (props: { data: DataDestinationStocking; animalsTransferred: number }) => {
    const { data, animalsTransferred } = props;

    if (dataDestinationStockings.length > 1 && data.animalsNumber && data.animalsNumber > 0) {
      return Number(data.animalsNumber);
    }

    return Number(animalsTransferred);
  };

  const getStartDateTransfer = (props: { endDate: string }) => {
    const { endDate } = props;

    if (selectedStocking.phaseType === newStockingPhaseType) {
      switch (selectedStocking.phaseType) {
        case stockingPhaseTypes.LARVAE:
          return selectedStocking.startDate;

        case stockingPhaseTypes.JUVENILE:
          return selectedStocking.startDateJuvenile;

        case stockingPhaseTypes.ADULT:
          return selectedStocking.startDateGrowOut;
      }
    }

    return endDate;
  };

  const injectVolume = (props: { data: DataDestinationStocking; tankType?: string; volume?: number }) => {
    const { data, tankType, volume } = props;

    switch (tankType) {
      case containerTypes.TANK:
        data.litersNumber = Number(volume);
        break;

      case containerTypes.RACEWAY:
        data.cubicMeters = Number(volume);
        break;

      case containerTypes.PRE_HATCHERY:
      case containerTypes.POOL:
        data.hectares = Number(volume);
        break;
    }

    return data;
  };
  const getDataDestinationStockings = () => {
    const date = endDate.format(DATE_FORMATS.YYYY_MM_DD).toString();

    return dataDestinationStockings.map((destinationStocking) => {
      const data: DataDestinationStocking = {
        customStage: selectedStocking.phaseType === stockingPhaseTypes.LARVAE && selectedStocking.phaseType === newStockingPhaseType ? selectedStocking.customStage : undefined,
        animalsNumber: getAnimalsNumber({ data: destinationStocking, animalsTransferred: Number(animalsTransferred) }),
        campusId: destinationStocking.campusId,
        code: destinationStocking.code,
        moduleId: destinationStocking.moduleId,
        name: destinationStocking.name,
        startDate: getStartDateTransfer({ endDate: date }),
        tankId: destinationStocking.tankId,
        stockingId: destinationStocking.stockingId,
      };

      return injectVolume({ data, tankType: destinationStocking.tankType, volume: destinationStocking.volume });
    });
  };

  const getStockingData = () => {
    const date = endDate.format(DATE_FORMATS.YYYY_MM_DD).toString();

    const stockingData: TransferStockingDataProps = {
      originPhaseType: selectedStocking.phaseType,
      destinationPhaseType: newStockingPhaseType,
      transferDate: date,
      poundsTransferred: Number(poundsTransferred),
      status: fullTransfer ? transferTypes.FULL_TRANSFER : transferTypes.PARTIAL_TRANSFER,
      averageHarvestedWeight: Number(averageHarvestedWeight),
      animalsTransferred: Number(animalsTransferred),
      plgTransferred: (selectedStocking.phaseType === stockingPhaseTypes.LARVAE) ? Number(plgHarvest) : undefined,
      destinationStockings: getDataDestinationStockings(),
    };

    return stockingData;
  };

  const onSubmit = () => {
    const paramsToFetchStocking = { companyId: company._id, campusId: selectedCampus?._id, moduleId: selectedModuleId, tankId: selectedTankId, page: currentPage, phaseType: selectedStocking.phaseType, stockingsToShow };
    const makeFetchStockings = pathname === '/production/stockings';
    const stockingData: TransferStockingDataProps = getStockingData();

    const params: TransferStockingProps = {
      stockingId: selectedStocking?._id,
      stockingData,
      makeFetchStockings,
      paramsToFetchStocking,
    };

    dispatch(transferStockingSlice.transferStocking(params));
  };
  /* End of functions section */

  /* Rendered functions */
  const renderNewDestinationStockingButton = () => {
    return (
      <ActionButton
        id='btn_new_reference_curve'
        className={styles.button}
        icon={<Icon name='add' />}
        onClick={addDestinationStocking}
        disabled={!isValidDataDestinationStocking()}
        theme='light'
        type='ghost'
      >
        {t('referenceCurve.new')}
      </ActionButton>
    );
  };

  const renderPhaseDropdownOptions = () => {
    switch (selectedStocking.phaseType) {
      case stockingPhaseTypes.LARVAE: {
        return Object.values(stockingPhaseTypes).map((phaseType) =>
          <Option key={phaseType} value={phaseType}>{t(`stockings.phaseTypes.${phaseType}`)}</Option>
        );
      }

      case stockingPhaseTypes.JUVENILE: {
        return (
          <>
            <Option key={stockingPhaseTypes.JUVENILE} value={stockingPhaseTypes.JUVENILE}>{t(`stockings.phaseTypes.${stockingPhaseTypes.JUVENILE}`)}</Option>
            <Option key={stockingPhaseTypes.ADULT} value={stockingPhaseTypes.ADULT}>{t(`stockings.phaseTypes.${stockingPhaseTypes.ADULT}`)}</Option>
          </>
        );
      }

      default:
        return null;
    }
  };

  const renderPhaseDropdown = () => {
    return (
      <Form.Item
        name='phaseType'
        label={t('stockings.phaseType')}
        required
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvSelect
          theme={theme}
          showSearch
          value={newStockingPhaseType}
          onChange={onChangeStockingPhaseType}
          suffixIcon={<Icon name='arrow-down-s' />}
          filterOption={filterOptionSelect}
          dropdownMatchSelectWidth={false}
        >
          {renderPhaseDropdownOptions()}
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderBodyDestinationStockings = () => {
    return dataDestinationStockings.map((destinationStocking, index) => {
      return (
        <DestinationStockingForm destinationStocking={destinationStocking} index={index} theme={theme} newStockingPhaseType={newStockingPhaseType} />
      );
    });
  };

  const renderFinishStocking = () => {
    return (
      <Form.Item
        name='finishStocking'
      >
        <LrvCheckbox
          theme={theme}
          checked={fullTransfer}
          onChange={event => {
            const checked = event.target.checked;
            setFullTransfer(checked);
          }}
        >
          <LrvText text={t('stockings.radio.close')} theme={theme} />
        </LrvCheckbox>
        {
          fullTransfer && <div className={styles.closeInfoMessage}>
            <LrvText text={t('stockings.closeInfoMessage')} theme={theme} />
          </div>
        }
      </Form.Item>
    );
  };

  const renderDestinationStocking = () => {
    return (
      <>
        <LrvDivider theme={theme} />
        <Row className={styles.titleNewStocking} align='middle' justify='space-between'>
          <LrvText text={t('stockings.finishStockingLabels.destinationStocking')} theme={theme} />
          {renderNewDestinationStockingButton()}
        </Row>

        {selectedStocking.phaseType !== stockingPhaseTypes.ADULT &&
          <Row gutter={16}>
            <Col span={24}>
              {renderPhaseDropdown()}
            </Col>
          </Row>
        }

        {renderBodyDestinationStockings()}
        {renderFinishStocking()}
      </>
    );
  };

  const renderKilogramsTransferred = () => {
    const requiredField = selectedStocking.phaseType !== stockingPhaseTypes.LARVAE;
    const label = `stockings.finishStockingLabels.${selectedStocking.phaseType}.kilogramsTransferred`;

    return (
      <Col
        span={12}
        hidden={company.weightUnit === weightUnitsByCompany.POUND}
      >
        <Form.Item
          name='kilogramsTransferred'
          label={requiredField ? t(label) : `${t(label)} (${t('common.optional')})`}
          required={requiredField}
          rules={requiredField ?
            [() => ({
              validator (rule, value) {
                return validateNumber(value, false, 1);
              }
            })] : undefined}
        >
          <LrvInputNumber
            theme={theme}
            value={kilogramsTransferred}
            min={selectedStocking.phaseType === stockingPhaseTypes.LARVAE ? undefined : 1}
            onChange={(value) => {
              const kilogramsTransferred: string | number = value || '';
              setKilogramsTransferred(kilogramsTransferred);
              calculatePoundsTransferred(kilogramsTransferred);

              const pounds = convertKilogramsToPounds(kilogramsTransferred);
              calculateShrimpsTransferred(pounds, averageHarvestedWeight);
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderPoundsTransferred = () => {
    const requiredField = selectedStocking.phaseType !== stockingPhaseTypes.LARVAE;
    const label = `stockings.finishStockingLabels.${selectedStocking.phaseType}.poundsTransferred`;

    return (
      <Col
        span={12}
        hidden={company.weightUnit === weightUnitsByCompany.KILOGRAM}
      >
        <Form.Item
          name='poundsTransferred'
          label={requiredField ? t(label) : `${t(label)} (${t('common.optional')})`}
          required={requiredField}
          rules={requiredField ?
            [() => ({
              validator (rule, value) {
                return validateNumber(value, false, 1);
              }
            })] : undefined}
        >
          <LrvInputNumber
            theme={theme}
            value={poundsTransferred}
            min={selectedStocking.phaseType === stockingPhaseTypes.LARVAE ? undefined : 1}
            onChange={(value) => {
              const poundsTransferred: string | number = value || '';
              setPoundsTransferred(poundsTransferred);
              calculateKilogramsTransferred(poundsTransferred);
              calculateShrimpsTransferred(poundsTransferred, averageHarvestedWeight);
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderAnimalsTransferred = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='animalsTransferred'
          label={t(`stockings.finishStockingLabels.${selectedStocking.phaseType}.animalsTransferred`)}
          required
          rules={[() => ({
            validator (rule, value) {
              return validateNumber(value, false, 1);
            }
          })]}
        >
          <LrvInputNumber
            theme={theme}
            value={animalsTransferred}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value)}
            min={1}
            readOnly={true}
            onChange={(value) => {
              const animalsTransferred = value || '';
              setAnimalsTransferred(animalsTransferred);
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderAverageHarvestedWeight = (props: { min?: number, max?: number, unit: string; span: number }) => {
    const { max, min, unit, span } = props;
    const label = `stockings.finishStockingLabels.${selectedStocking.phaseType}.transferWeight`;

    return (
      <Col span={span}>
        <Form.Item
          name='averageHarvestedWeight'
          label={`${t(label)} ${unit}`}
          required
          rules={[() => ({
            validator (rule, value) {
              return validateNumber(value, false, min);
            }
          })]}
        >
          <LrvInputNumber
            theme={theme}
            value={averageHarvestedWeight}
            min={min}
            max={max}
            onChange={(value) => {
              const averageWeight: string | number = value || '';
              setAverageHarvestedWeight(averageWeight);
              calculatePlgHarvest(averageWeight);

              calculateShrimpsTransferred(poundsTransferred, averageWeight);
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderPlgHarvest = (props: { title: string; span: number }) => {
    const { title, span } = props;
    const { min, max } = stockingFinishedData.larvae.larvaePerGram;

    return (
      <Col span={span}>
        <Form.Item
          name='plgHarvest'
          label={title}
          required
          rules={[() => ({
            validator (rule, value) {
              return validateNumber(value, false, min, max, t('stockings.plgHarvestError', { min, max }));
            }
          })]}
        >
          <LrvInputNumber
            theme={theme}
            value={plgHarvest}
            min={min}
            max={max}
            onChange={(value) => {
              const plgHarvest: string | number = value || '';
              setPlgHarvest(plgHarvest);
              calculateAverageHarvestedWeight(plgHarvest);
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderEndDateInput = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='endDate'
          label={t('stockings.transferDate')}
          required
          rules={[{ required: true, message: t('stockings.form.endDate') }]}
        >
          <LrvDatePicker
            theme={theme}
            placeholder={t('stockings.selectEndDate')}
            disabledDate={(currentDate) => disabledEndDate({ currentDate, stockingStartDate: getStartDate(selectedStocking) })}
            value={moment(endDate)}
            onChange={(date) => setEndDate(date ?? moment())}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderBodyLarvaeFinishForm = () => {
    const { max, min } = stockingFinishedData.larvae.larvaePerGram;

    return (
      <Row gutter={16}>
        {renderKilogramsTransferred()}
        {renderPoundsTransferred()}
        {renderPlgHarvest({ title: t(`stockings.finishStockingLabels.${selectedStocking.phaseType}.plgTransferred`), span: 12 })}
        {renderAverageHarvestedWeight({ min, max, unit: weightUnits.MG, span: 12 })}
        {renderAnimalsTransferred()}
        {renderEndDateInput()}
      </Row>
    );
  };

  const renderBodyJuvenileFinishForm = () => {
    const { min } = stockingFinishedData.juvenile.averageHarvestedWeight;

    return (
      <Row gutter={16}>
        {renderKilogramsTransferred()}
        {renderPoundsTransferred()}
        {renderAverageHarvestedWeight({ min, unit: weightUnits.G, span: 12 })}
        {renderAnimalsTransferred()}
        {renderEndDateInput()}
      </Row>
    );
  };

  const renderBodyGrowOutFinishForm = () => {
    const { min } = stockingFinishedData.growOut.averageHarvestedWeight;

    return (
      <Row gutter={16}>
        {renderKilogramsTransferred()}
        {renderPoundsTransferred()}
        {renderAverageHarvestedWeight({ min, unit: weightUnits.G, span: 12 })}
        {renderAnimalsTransferred()}
        {renderEndDateInput()}
      </Row>
    );
  };

  const renderCancelButton = () => {
    return (
      <LrvButton
        className={styles.cancelButton}
        theme={theme}
        type='text'
        onClick={() => {
          formTransferStocking.resetFields();
          dispatch(finishStockingSlice.setShowModalFinish(false));
          dispatch(finishStockingSlice.setDataDestinationStocking([defaultDestinationStocking]));
        }}
      >
        <LrvText theme={theme} text={t('stockings.cancel')} />
      </LrvButton>
    );
  };

  const renderSubmitButton = () => {
    return (
      <ActionButton
        className={styles.submitButton}
        theme='light'
        type='primary'
        htmlType='submit'
        onClick={onSubmit}
        disabled={disabledFinishForm()}
        loading={isLoadingFinish || isLoadingTransfer}
      >
        <LrvText theme='dark' text={t('stockings.finishStockingLabels.transfer')} />
      </ActionButton>
    );
  };
  /* End of rendered functions */

  return (
    <div className={styles.formContainer}>
      <LrvForm
        className={styles.formTransferStocking}
        theme={theme}
        form={formTransferStocking}
        name='formTransferStocking'
        id='formTransferStocking'
        layout='vertical'
      >
        {selectedStocking.phaseType === stockingPhaseTypes.LARVAE && renderBodyLarvaeFinishForm()}
        {selectedStocking.phaseType === stockingPhaseTypes.JUVENILE && renderBodyJuvenileFinishForm()}
        {selectedStocking.phaseType === stockingPhaseTypes.ADULT && renderBodyGrowOutFinishForm()}
        {renderDestinationStocking()}
      </LrvForm>

      <div className={styles.buttonsContainer}>
        {renderCancelButton()}
        {renderSubmitButton()}
      </div>
    </div>
  );
};
